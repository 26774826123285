<template>
    <v-container>
        <base-material-card icon="mdi-basket" title="Devolución de Productos de Vendedores" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="$router.push({name: 'AlmacenesDescargaCreate'})">Nuevo</v-btn>
                </v-col>
                <v-col cols="6">
                    <custom-date label="Fecha Desde"
                    @change="(newValue)=>{form.fecha_desde= newValue;load()}"
                    :value="form.fecha_desde"></custom-date>
                </v-col>
                <v-col cols="6">
                    <custom-date label="Fecha Hasta"
                    @change="(newValue)=>{form.fecha_hasta= newValue;load()}"
                    :value="form.fecha_hasta"></custom-date>
                </v-col>
              <v-col cols="6">
                <custom-select
                  url="/vendedores/all"
                  text="razon_social"
                  label="Vendedor"
                  @change="
							(value) => {
								form.vendedor_id = value;
								load();
							}
						"
                  :valor="form.vendedor_id"
                  :first-select="true"
                  first-text="Todos"
                ></custom-select>
              </v-col>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="familias" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                      <template v-slot:item.importe="{item}">
                        {{formatoMoneda(calcularImporte(item))}}
                      </template>
                      <template v-slot:item.accciones="{item}">
                            <v-btn style="margin-right:5px !important;" fab icon x-small
                            @click="$router.push({name: 'AlmacenesDescargaEdit',params: {id: item.id}})">
                                <v-icon>far fa-eye</v-icon>
                            </v-btn>
                            <v-btn fab icon x-small @click="descargaPdf(item.id)">
                                <v-icon>fa fa-download</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col col="12">
                    <v-pagination v-model="pagination.currentPage" class="my-4" :length="pagination.lastPage" @input="load" :total-visible="10" color="primary"></v-pagination>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    import moment from 'moment';
    import CustomDate from "@views/dashboard/component/CustomDate.vue";
    import CustomSelect from "@views/dashboard/component/CustomSelect.vue";

    export default {
        components: {
			CustomDate,
          CustomSelect
		},
        data: () => ({
            headers: [
                { text: 'Fecha', value: 'fecha', sortable: false },
                { text: 'Vendedor', value: 'vendedor.razon_social', sortable: false },
              { text: 'Importe', value: 'importe', sortable: false },
                { text: 'Acciones', value: 'accciones', sortable: false },
            ],
            familias: [],
            pagination: {
                perPage: 10,
                lastPage: 1,
                currentPage: 1
            },
            form:{
                fecha_desde: moment().startOf('day').format('YYYY-MM-DD'),
                fecha_hasta: moment().startOf('day').format('YYYY-MM-DD'),
              vendedor_id: ''
            }
        }),
        mounted() {
            this.load();
        },
        methods: {
            descargaPdf(id){
                this.$http({
                    url: `/almacenes/descargas/${id}/pdf`,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', `DescargaProductosVendedor_${id}.pdf`); //any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                });
            },
            load() {
                this.$http.get('/almacenes/descargas', {
                    params: {
                        page: this.pagination.currentPage,
                        ...this.form
                    }
                }).then(response => {
                    this.familias = response.data.data
                    this.pagination.lastPage = response.data.last_page
                    this.pagination.currentPage = response.data.current_page
                })
            },
          calcularImporte(item){
            console.log('calcularImporte', item)
            const impuestos = this.impuestos(item.detalles);
            const impuestosTotal = impuestos && impuestos.length
              ? impuestos.reduce((acc, item) => {
                return acc + item.importe;
              }, 0)
              : 0;
            return impuestosTotal + item.detalles.reduce((acc, item) => {
              return acc + item.importe;
            }, 0);
          },
          impuestos(detalles) {
            console.log('impuestos')
            if (detalles && detalles.length) {
              let impuestosGroup = [];
              const impuestos = detalles
                // Agregando datos del producto en impuestos
                .map((item) =>
                  item.producto.impuestos.map((impuesto) => {
                    const importe =
                      item.importe * (impuesto.porcentaje / 100);
                    impuesto["producto"] = item.producto.nombre;
                    impuesto["cantidad"] = item.cantidad;
                    impuesto["producto_importe"] = item.importe;
                    impuesto["importe"] = parseFloat(parseFloat(importe).toFixed(2));
                    return impuesto;
                  })
                )
                // Convirtiendo datos de 2 dimensiones a 1
                .flat()
                .map((impuesto) => {
                  let existeImpuesto = impuestosGroup.find((group) => {
                    return group && group.nombre == impuesto.nombre;
                  });
                  if (existeImpuesto) {
                    console.log("impuesto existe");
                    existeImpuesto["importe"] += impuesto["importe"];
                  } else {
                    impuestosGroup.push(impuesto);
                  }
                });
              return impuestosGroup;
            }
            return 0;
          },
        }
    }
</script>
